<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
      </b-link>
      <b-col lg="7" class="d-none d-lg-flex align-items-center">
        <div class="w-100 d-lg-flex align-items-center justify-content-center mt-5 ">
          <b-img fluid :src="logo_siembal" alt="Login V2" class="mt-5" style="ustify-self: bottom" />
        </div>
      </b-col>
      <b-col lg="5" class="d-flex auth-bg px-2 p-lg-5 "> 
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto  mt-5">
         <registrasi-info :info = rs v-if="st == '1'"></registrasi-info>
<div  v-if="st =='0'">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center  mt-5" >
            <h4 class="text-primary">
              Registrasi Akun Pengaduan Masyarakat 👋
            </h4>
            <!-- -->
          </b-card-title>
          <b-card-text class="mb-2">
          </b-card-text>
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Nama Lengkap" label-for="login-nama_lengkap">
                <validation-provider #default="{ errors }" name="Nama Lengkap" rules="required">
                  <b-form-input id="login-nama_lengkap" v-model="input.nm_lengkap"
                    :state="errors.length > 0 ? false:null" name="login-nama_lengkap" placeholder="Nama Lengkap" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nomor Hp/WA" label-for="login-no_tlp">
                <validation-provider #default="{ errors }" name="Nomor Tlp/WA" rules="required|min:10">
                  <b-form-input id="login-no_tlp" v-model="input.no_hp" :state="errors.length > 0 ? false:null"
                    type="number" name="login-no_tlp" placeholder="Nomor Tlp/WA" />
                  <small class="text-warning"><i>** No HP/WA akan di gunakan menjadi username saat login</i></small>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Jenis Kelamin" label-for="login-no_tlp">
                <b-row>
                  <b-col md="6">
                    <b-form-radio v-model="input.jenis_kelamin" name="some-radios" value="Laki-laki">
                      Laki-laki
                    </b-form-radio>
                  </b-col>
                  <b-col md="6">
                    <b-form-radio v-model="input.jenis_kelamin" name="some-radios" value="Perempuan">
                      Perempuan
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="login-password" v-model="input.password" :state="errors.length > 0 ? false:null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Ketik Ulang Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="login-password" v-model="input.password2" :state="errors.length > 0 ? false:null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button type="submit" variant="primary" block @click="validationForm()"
                v-if="input.password2 == input.password & input.jenis_kelamin != null">
                <feather-icon icon="UserCheckIcon" size="15"></feather-icon> Registrasi
              </b-button>
              <small><i>Tombol registrasi akan aktif jika semua data sudah terisi dengan benar</i></small>
            </b-form>
          </validation-observer>

          <router-link to="/">
            <b-button type="submit" variant="warning" block @click="validationForm()" class="mt-1">
              <feather-icon icon="ArrowLeftCircleIcon" size="15"></feather-icon> Kembali Halama Login
            </b-button>
          </router-link>
         </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
  /* eslint-disable global-require */
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BFormRadio
  } from 'bootstrap-vue'
  import {
    required,
    min,
    digits,
    length,
    email
  } from '@validations'
  import {
    togglePasswordVisibility
  } from '@core/mixins/ui/forms'
  import store from '@/store/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from '../../../config/Axios';
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import RegistrasiInfo from './RegistrasiInfo.vue'
  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
      FeatherIcon,
      BFormRadio,
         RegistrasiInfo
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        input: {},
        rs: {},
        st:0,
        sideImg: require('@/assets/images/pages/coming-soon.svg'),
        logo_siembal: require('@/assets/images/pages/registrasi_pm2.png'),
        fllaj: require('@/assets/images/pages/fllaj.png'),
        email,
        required,
        min,
        digits,
        length,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.sideImg = require('@/assets/images/pages/logo-1.png')
          return this.sideImg
        }
        return this.sideImg
      },
    },
    methods: {
      async Profil(isAuthenticated) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/aut/profil',
            headers: {
              'Authorization': isAuthenticated
            }
          })
          .then(function (response) {
            self.resultUser = response.data;
            // self.SetUser(response.data.result);
          }).catch(err => {
            console.log(err);
          });

      },
      async get_ip() {
        const self = this;
        await axios_d({
            method: 'GET',
            url: 'https://api.ipify.org?format=json',
            responseType: 'json',
          })
          .then(function (response) {
            self.ip = response.data.ip
          }).catch(err => {
            console.log(err);
          });

      },

      validationForm() {
        this.$refs.loginValidation.validate().then(success => {
          if (success) {
            const self = this;
            axios({
                method: 'POST',
                url: '/api/registrasi/add_user',
                data: self.input
              })
              .then(function (response) {
                self.rs = response.data;
                self.st ='1';
                self.input = {}
              }).catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Maaf...',
                    text: ' ada gagal registrasi',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                });
              });
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>